import React, { Component } from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import Cookies from "../components/About/Cookies";
import config from "../../data/SiteConfig";

class CookiesPage extends Component {
  render() {
    return (
      <Layout>
        <div className="cookies-container">
          <Helmet title={`Cookies | ${config.siteTitle}`} />
          <Cookies />
        </div>
      </Layout>
    );
  }
}

export default CookiesPage;
